import React from 'react';
import './footer.scss';

function Footer() {
  return (
    <div className="footer section">

    </div>
  )
}

export default Footer;